export var TransactionType;
(function (TransactionType) {
    TransactionType["UNCONFIRMED_DELIVERY"] = "UNCONFIRMED_DELIVERY";
    TransactionType["UNCONFIRMED_DELIVERY_REVERSION"] = "UNCONFIRMED_DELIVERY_REVERSION";
    TransactionType["UNCONFIRMED_RECEPTION"] = "UNCONFIRMED_RECEPTION";
    TransactionType["UNCONFIRMED_RECEPTION_REVERSION"] = "UNCONFIRMED_RECEPTION_REVERSION";
    TransactionType["ONSITE_TRANSPORT"] = "ONSITE_TRANSPORT";
    TransactionType["REMOTE_TRANSPORT"] = "REMOTE_TRANSPORT";
    TransactionType["PROCESS"] = "PROCESS";
    TransactionType["PROCESS_REVERSION"] = "PROCESS_REVERSION";
    TransactionType["DEPOSIT"] = "DEPOSIT";
})(TransactionType || (TransactionType = {}));
export function validateType(type) {
    if (!(type in TransactionType)) {
        throw new Error(`Invalid type: ${type}`);
    }
    return type;
}
export function isUnconfirmed(type) {
    return isUnconfirmedDelivery(type) || isUnconfirmedReception(type) || isDeposit(type);
}
export function isUnconfirmedDelivery(type) {
    return type === TransactionType.UNCONFIRMED_DELIVERY;
}
export function isUnconfirmedReception(type) {
    return type === TransactionType.UNCONFIRMED_RECEPTION;
}
export function isDeposit(type) {
    return type === TransactionType.DEPOSIT;
}
export function isRemoteTransport(type) {
    return type === TransactionType.REMOTE_TRANSPORT;
}
export function isOnsiteTransport(type) {
    return type === TransactionType.ONSITE_TRANSPORT;
}
export function isProcess(type) {
    return type === TransactionType.PROCESS;
}
